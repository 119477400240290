import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapefour from "../images/bannerElement/white-design-element-strategic.png"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import CompetitiveDemand from "../images/icons/StrategicPlanning/CompetitiveDemandGenerationStrategies.svg"
import DataDriven from "../images/icons/StrategicPlanning/DataDrivenleadNurturingPrograms.svg"
import IndepthBusiness from "../images/icons/StrategicPlanning/IndepthBusinessAudienceAnalysis.svg"
import RelevanceConsideration from "../images/icons/StrategicPlanning/RelevanceConsiderationFrameworks.svg"
import StrategicDiscover from "../images/icons/StrategicPlanning/StrategicPlanningDiscover.svg"
import StrategicImplement from "../images/icons/StrategicPlanning/StrategicPlanningImplement.svg"
import StrategicOptimize from "../images/icons/StrategicPlanning/StrategicPlanningOptimize.svg"
import StrategicRoadmap from "../images/icons/StrategicPlanning/StrategicPlanningRoadmap.svg"
import StrategicStrategize from "../images/icons/StrategicPlanning/StrategicPlanningStrategize.svg"
import UnifiedCustomer from "../images/icons/StrategicPlanning/UnifiedCustomerCentricApproach.svg"
import ValueMessaging from "../images/icons/StrategicPlanning/ValueMessaging.svg"
import image1 from "../images/image-one.svg"
import image3 from "../images/image-three.svg"
import image2 from "../images/image-two.svg"
import "../styles/404.css"

const CreativeAssets = ({ siteTitle }) => {
  const [countUpVisible, setCountUpVisible] = useState(false)
  const handleVisibilityChange = isVisible => {
    if (isVisible) {
      setCountUpVisible(true)
    }
  }
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Strategic Planning | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="strategic-planning digital-consulting">
          <section id="Banner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>Strategy for your transformational growth</h1>
                    <p>
                      Align your business goals, resources, and teams for the
                      future
                    </p>
                    <a className="button" id="Get-in-touch" href="/contact-us/">
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2>Unlock growth potential with strategic planning</h2>
              <p>
                In today's digital landscape, businesses require comprehensive
                strategies tailored to their unique goals. Our bespoke plans
                bridge your vision with tangible success.
              </p>
            </div>
          </section>
          {/* <section id="PageIntro">
						<div class="container">
							<h2>Strategic foresight meets practical application</h2>
							<p>We combine strategic foresight with practical application. After analyzing your business context, we identify pain points and craft innovative solutions that steer you toward growth.</p>
						</div>
					</section> */}
          <section id="Approach">
            <div class="container">
              <h2>Our core offerings</h2>
              <div class="horizontalImageIconSec">
                <div class="moduleWrap">
                  <div class="eachModule">
                    <div class="icons">
                      <img width="100" height="100" src={IndepthBusiness} />
                    </div>
                    <div class="contents">
                      <p>In-depth Business and Audience Analysis</p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img width="100" height="100" src={ValueMessaging} />
                    </div>
                    <div class="contents">
                      <p>Value Messaging Aligned to Buyer Journeys</p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img width="100" height="100" src={CompetitiveDemand} />
                    </div>
                    <div class="contents">
                      <p>Competitive and Demand Generation Strategies</p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        width="100"
                        height="100"
                        src={RelevanceConsideration}
                      />
                    </div>
                    <div class="contents">
                      <p>Relevance and Consideration Frameworks</p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img width="100" height="100" src={UnifiedCustomer} />
                    </div>
                    <div class="contents">
                      <p>Unified, Customer-Centric Approach</p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img width="100" height="100" src={DataDriven} />
                    </div>
                    <div class="contents">
                      <p>Data-Driven Lead Nurturing Programs</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="Process">
            <div class="container">
              <h2>Our process</h2>
              <p>
                Our process begins with discovery, researching your business,
                market, and customers to identify pain points and growth
                opportunities. We then strategize, defining strategic
                frameworks, objectives, and key metrics, and crafting
                data-driven growth strategies. A roadmap is created to bridge
                vision and execution with integrated strategic plans.
                Implementation involves operationalizing strategies across the
                organization and launching campaigns and initiatives. Finally,
                we optimize by tracking performance against goals and iterating
                strategies based on insights for continuous improvement.
              </p>
              <div class="multiStageProcess">
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={StrategicDiscover} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image1} />
                    </span>
                  </div>
                  <div class="processContent">Discover</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={StrategicStrategize} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Strategize</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={StrategicRoadmap} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Roadmap</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={StrategicImplement} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Implement</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={StrategicOptimize} />
                    <span class="borderImg">
                      <img width="160" height="161" src={image3} />
                    </span>
                  </div>
                  <div class="processContent">Optimize</div>
                </div>
              </div>
              <div class="bringideas">
                <h2>Transform your approach </h2>
                <p>
                  With an empathy-driven strategy tailored to your goals, we
                  help build a future of sustainable growth and success. Our
                  proven approach delivers strategized growth you can measure.
                </p>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>Take the first step towards growth</h2>
              <a className="button" id="Get-in-touch" href="/contact-us/">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default CreativeAssets
